.div1{
    height: 500px;
    color: #00061A;
}
.Nav-bar{
    display: flex;
    align-items: center;
    gap: 20px;
}
.body{
    color: #00061A;
}
.logo{
    padding-right: 50px;
    display: flex;
    align-items: center;
}
.nav-link{
    color: white;
    position: relative;
    text-decoration:none ;
    font-weight: 900;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    padding-left:20px;
    padding-right: 20px;
}
.navicon{
    display: none;
    font-size: 18px; 
}
.navbar{
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.nav-menu{
    padding-left: 220px;
    box-sizing: border-box;
}
.NavClose{
    color: yellow;
    cursor: pointer;
    transition: .5s;
    height: 150px;       
    width: 40px;
}
.NavBar{
    display: flex;
    background-color: #00061A;
    height:90px ;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
}
.nav-button{
    
    border-radius: 10px;
    height: 50px;
    width: 100%;
    background: linear-gradient(219deg, #FB0E76 0%, #910694 100%);
    align-items: center;
    outline: none;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 100px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2.4px;
    font-weight: 900;
    font-family: 'Rubik', sans-serif;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
    width: 200px;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: background-position .5s;
    gap: 10px;
}
.nav-button:hover{
    background-position: 0 0;
}
.nav-link:hover{
    color: yellow;
}
@media screen and (max-width:1430px) {
    .nav-button{
        display: none;
    }
}
@media screen and (max-width:1100px){
    .navicon{
        display: flex;
    }
    .nav-menu{
        padding: 0;
    }
    .navicon{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        width: 100%;
    }
    .NavMenu{
        color: yellow;
        height: 150px;
        width: 40px;
        cursor: pointer;
        right: 10%;
    }
    .navbar{
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 95%;
    }
    .sidebar{
        display: flex;
    }
    .Nav-bar{
        display: flex;
        width: 100%;
        position: absolute;
        top: 74.2px;
        left: -110%;
        align-items: center;
        text-decoration: none;
        flex-direction: column;
        list-style-type: none;
        box-sizing: border-box;
        background-color: #00061A;
        transition: left .5s;
    }
    .NavClose.active{
        transform: rotate(180deg);
    }
    .NavClose:hover{
        transform: rotate(180deg);
    }
    .Nav-bar.active{
        left: 0%;
    }
    .nav-sidebar{
        color: #00061A;
    }
    .nav-item{
        border-bottom: 1px solid white;
        padding:10px;
    }
}